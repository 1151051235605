/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import GreenLine from '../components/GreenLine'
import TextContainer from '../components/TextContainer'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import SEO from '../components/SEO'

import 'semantic-ui-css/components/table.min.css'
import 'semantic-ui-css/components/grid.min.css'

const JobTemplate = props => {
  const {
    title,
    subtitle,
    slug,
    heroImage,
    body,
    downloads,
    pensum,
    placeOfWork,
    startDate,
    qualification,
  } = props.data.contentfulJob

  const StyledLink = styled.a`
    font-weight: 600;
    color: #C2CF00 !important;
    &:hover {
      color: #474A4A;
      transition: color 0.3s;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      will-change: color:
    }
  `

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} />

      {heroImage ? (
        <PageTitle background={heroImage} />
      ) : (
        <GreenLine backgroundColor="rgba(0,0,0,0)" />
      )}

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </TextContainer>
        <TextContainer>
          {body && <PageBody body={body} />}
          <div
            className={`ui stackable ${downloads ? 'two' : 'one'} column grid`}
            style={{
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
          >
            <div className="column">
              <h3>Die Details</h3>
              <table className="ui basic unstackable table">
                {pensum && (
                  <tr>
                    <td>Pensum:</td>
                    <td>{pensum}</td>
                  </tr>
                )}
                {placeOfWork && (
                  <tr>
                    <td>Arbeitsort:</td>
                    <td>{placeOfWork}</td>
                  </tr>
                )}
                {qualification && (
                  <tr>
                    <td>Qualifikation:</td>
                    <td>{qualification}</td>
                  </tr>
                )}
                {startDate && (
                  <tr>
                    <td>Start:</td>
                    <td>{startDate}</td>
                  </tr>
                )}
              </table>
            </div>
            {downloads && (
              <div className="column">
                <h3>Downloads</h3>
                <table className="ui selectable unstackable table">
                  {downloads.map((el, i) => (
                    <tr>
                      <td>
                        <i
                          className={`icon file ${
                            el.file.contentType.split('/')[1]
                          }`}
                        />
                        {el.title}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <StyledLink href={el.file.url}>Herunterladen</StyledLink>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            )}
          </div>
        </TextContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulJob(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "DD-MM-YYYY")
      heroImage {
        title
        fluid(
          quality: 100
          maxWidth: 1920
          resizingBehavior: NO_CHANGE
          toFormat: NO_CHANGE
        ) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
          ...GatsbyContentfulFluid
        }
        ogimg: resize(width: 1280) {
          src
          width
          height
        }
      }
      body {
        json
      }
      # subtitle
      startDate
      pensum
      placeOfWork
      # qualification
      # downloads {
      #   title
      #   file {
      #     fileName
      #     url
      #     contentType
      #   }
      # }
    }
  }
`

export default JobTemplate
